<template>
    <el-main>
        <div class="title">消息中心</div>
        <el-tabs v-if="tabsList.length > 0" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in tabsList" :key="index" :label="item.title"
                :name="String(item.id)"></el-tab-pane>
        </el-tabs>
        <el-button type="primary" size="small" @click="allRead">全部已读</el-button>
        <el-table v-if="tabsList.length > 0" :data="showList" stripe :header-cell-style="{ 'background-color': '#F8F9FA' }"
            row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column label="标题" align="center">
                <template v-slot="{ row }">
                    <div class="titleBox">
                        <div class="red" v-if="activeName === '3' && !row.is_read && !loading"></div>
                        <div class="red" v-if="activeName !== '3' && !row.read_state && !loading"></div>
                        <div v-if="activeName !== '3'" class="tableTitle">{{ row.title }}</div>
                        <div v-else class="tableTitle">{{ row.content }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="类型" align="center">
                <template>
                    <div>{{ typeName }}</div>
                </template>
            </el-table-column>
            <el-table-column label="发布时间" align="center">
                <template v-slot="{ row }">
                    {{ getDateformat(row.create_time) }}
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <span class="lookBtn" @click="dealFn(row)">
                        {{ activeName === '3' ? '去处理' : '查看内容' }}
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import Paging from '@/components/paging'
import { getDateformat } from '@/util/getDate'
export default {
    components: {
        Paging
    },
    data () {
        return {
            tabsList: [],
            activeName: '1',
            typeName: '',
            uniacidSrc: null,
            page: 1,
            rows: 10,
            total: 0,
            showList: [],
            getDateformat,
            loading: false,
            removeCount: 0
        }
    },
    created () {
        this.getTabsList()
        this.getMsgList(this.activeName)
    },
    methods: {
        getTabsList () {
            this.$axios.post(this.$api.homeIndex.typeList).then((res) => {
                if (res.code === 0) {
                    this.tabsList = res.result.list
                    this.typeName = this.tabsList[0].title
                    console.log(this.typeName)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        handleClick () {
            this.page = 1
            this.getMsgList(this.activeName, 1)
            this.loading = true
            this.$emit('removeMsg')
        },
        getMsgList (type, status) {
            this.$axios
                .post(this.$api.homeIndex.tabsMessage, {
                    page: this.page,
                    rows: this.rows,
                    type_id: type
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.showList = res.result.list
                        this.total = res.result.total_number
                        if (status) {
                            this.typeName = this.tabsList[this.activeName - 1].title
                        }
                        this.loading = false
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        updateData (val, status) {
            if (status == 0) {
                this.rows = val
            } else {
                this.page = val
            }
            this.getMsgList(this.activeName)
        },
        dealFn (item) {
            let type = Number(this.activeName)
            if (this.activeName === '3' && !item.is_read) {
                this.$axios.post(this.$api.homeIndex.readMsg, {
                    message_id: item.id,
                    message_type: type
                })
                this.$emit('removeMsg')
                item.is_read = 1
            }
            if (this.activeIndex !== '3' && !item.read_state) {
                this.$axios.post(this.$api.homeIndex.readMsg, {
                    message_id: item.id,
                    message_type: type
                })
                this.$emit('removeMsg')
                console.log(11111111111)
                item.read_state = 1
            }
            if (this.activeName === '3') {
                this.toDealMsg(item)
            } else {
                this.$router.push({
                    path: '/noticeDetail',
                    query: item
                })
            }
        },
        toDealMsg (item, index) {
            if (item.type == 6) {
                this.$message({
                    message: '暂未开放客服模块',
                    type: 'warning'
                })
                return
            }
            let path = ''
            let that = this
            let case_id = localStorage.getItem('case_id')
            switch (item.type) {
                case 1:
                    path = case_id == 15 ? '/repair/goods/orderlist?tab_type=state_pay' : '/order/orderList?tab_type=state_pay';
                    break
                case 2:
                    path = case_id == 15 ? '/repair/goods/salelist?type=1' : '/order/salesRefund';
                    break
                case 3:
                    path = `/store/storeManagement?id=${item.store_id}`
                    break
                case 4:
                    path = `/store/reflectAudit?id=${item.store_id}`
                    break
                case 5:
                    path = '/set/weixin?from=1'
                    break
                case 6:
                    path = ''
                    break
                case 7:
                    path = case_id == 15 ? '/repair/goods/evaluate' : '/goods/GoodsEvaluate';
                    break
                case 8:
                    path = '/user/userList'
                    break
                case 9:
                    path = '/user/userList'
                    break
                case 10:
                    path = '/user/userList'
                    break
                case 50:
                    path = '/user/repairSettledAuditing';
                    break;
                case 51:
                    path = '/user/repairSettledList';
                    break;
                case 52:
                    path = '/user/repairSettledList';
                    break;
                case 53:
                    path = '/user/repairPartnerReview';
                    break;
                case 54:
                    path = '/user/repairStoreAuditList';
                    break;
                case 55:
                    path = '/repair/goods/salelist';
                    break;
                case 56:
                    path = '/repair/finance/depositRefund';
                    break;
                case 57:
                    path = '/repair/finance/artisanWithdraw';
                    break;
                case 58:
                    path = '/repair/finance/storeWithdraw';
                    break;
                case 59:
                    path = '/repair/finance/accounts';
                    break;
                case 60:
                    path = '/marketing/repairAdvertisingReview';
                    break;
                default:
                    break
            }

            if (this.$route.fullPath !== path) {
                that.$router.push(path)
            }
        },
        allRead () {
            let that = this
            let type = Number(this.activeName)
            this.$axios
                .post(this.$api.homeIndex.readMsg, {
                    message_id: 'all',
                    message_type: type
                })
                .then((res) => {
                    if (res.code == 0) {
                        that.$message.success('处理成功')
                        that.getMsgList(type)
                        that.getAllNum()
                    } else {
                        that.$message.error('处理失败')
                    }
                })
        },
        getAllNum () {
            this.$axios.post(this.$api.homeIndex.unReadCount).then((res) => {
                if (res.code === 0) {
                    if (this.activeName === '1') {
                        this.removeCount = res.result.un_read_num_notice
                    } else if (this.activeName === '2') {
                        this.removeCount = res.result.un_read_num_system
                    } else {
                        this.removeCount = res.result.un_read_num_message
                    }
                    this.$emit('removeMsg', 1, this.removeCount)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;
    padding-left: 30px;

    .title {
        font-size: 18px;
        font-weight: bold;
        position: relative;
        margin-bottom: 10px;

        &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 20px;
            background-color: #1467ff;
            position: absolute;
            top: 0;
            left: -10px;
        }
    }

    .el-table {
        margin-top: 20px;

        .titleBox {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .red {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: red;
            margin-right: 5px;
        }

        .tableTitle {
            width: 320px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .lookBtn {
            display: inline-block;
            border: 1px solid #1467ff;
            color: #1467ff;
            padding: 3px 30px;
            font-size: 12px;
            border-radius: 7px;
            cursor: pointer;
            user-select: none;
        }
    }
}
</style>
